import React, { useState, useEffect, useContext } from "react"
import styled from "@emotion/styled"

import { LocationContext } from "../contexts/LocationContext"
import Card from "./Card"
import Cta from "./Cta"
import Partners from "./Partners"
import Loading from "./Loading"
import LocationSelect from "./LocationSelect"

const Container = styled.div`
  padding: 0 3rem;
  max-width: 130rem;
  margin: 6rem auto;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const SecondaryWrapper = styled.div`
  padding: 1.5rem 0;
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  color: #505050;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 3rem;
  .card {
    margin: 1.5rem;
    padding: 3rem 1.5rem;
    width: 30%;
    min-width: 36rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 40em) {
      width: 100%;
      min-width: 0;
    }
  }
  @media only screen and (max-width: 32.5em) {
    padding: 3rem 0;
  }
`

const Details = styled.div`
  max-height: 35rem;
  overflow: hidden;
`

const Image = styled.img`
  width: 90%;
  height: 13rem;
  object-fit: contain;
`

// const Goal = styled.span`
//   text-align: right;
//   display: block;
//   padding-right: 5%;
// `
//
// const LoadingBar = styled.div`
//   margin: 0 auto;
//   height: 3rem;
//   border-radius: 50px;
//   padding: 0.4rem;
//   width: 90%;
//   border: 1px solid #909090;
//   overflow: hidden;
//   span {
//     display: block;
//     border-radius: 50px;
//     height: 100%;
//     background: linear-gradient(to right, #2dc7ff, #1976d2);
//   }
// `

const Body = styled.p`
  padding: 1.5rem 0;
  font-weight: 400;
  color: #505050;
`

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${Secondary} {
    margin-right: 1.5rem;
  }
`

const Fundraising = ({ data: { title, subtitle, cta }, images }) => {
  const { loc } = useContext(LocationContext)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  function toCamelCase(string) {
    string = string
      .toLowerCase()
      .replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase()
      })
    return string.charAt(0).toLowerCase() + string.substring(1)
  }

  useEffect(() => {
    if (typeof window !== "undefined" && "IntersectionObserver" in window) {
      const observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: "0px",
        threshold: 0.25,
      })
      observer.observe(document.querySelector("#featuredCharities"))
    } else {
      setLoading(true)
    }
  }, [])

  useEffect(() => {
    setData([])
    getCharities()
  }, [loc])

  async function getCharities() {
    try {
      const res = await fetch(
        "https://app.skipthedepot.com/api/getFeaturedCharities",
        // "http://localhost:3001/getFeaturedCharities",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )

      const json = await res.json()

      const cityCharites = []
      const areaCharities = [] // Fallback if city has no featured charities

      json.charities.map((charity) => {
        const regionsFeaturedIn = charity.regionsFeaturedIn.map(({ name }) =>
          toCamelCase(name)
        )

        if (regionsFeaturedIn.includes(loc.city)) {
          cityCharites.push(charity)
        }

        if (regionsFeaturedIn.includes(loc.area)) {
          areaCharities.push(charity)
        }
      })

      setData(cityCharites.length > 0 ? cityCharites : areaCharities)
    } catch (error) {
      console.log(error)
    }
  }

  function handleIntersection(entries) {
    if (entries[0].isIntersecting) {
      setLoading(true)
    }
  }

  function renderData(data) {
    return data.map(({ name, url, logo, mission, goal, current }, count) => (
      <Card key={count}>
        <Details>
          <Image src={logo} />
          <Secondary>{name}</Secondary>
          {/* <Goal>${goal}</Goal>
              <LoadingBar>
                <span style={{ width: `${(current / goal) * 100}%` }} />
              </LoadingBar> */}
          <Body>
            {mission.length > 63 ? `${mission.slice(0, 60)}...` : mission}
          </Body>
        </Details>
        <Cta href={`https://app.skipTheDepot.com/${url}`} variant>
          Donate now
        </Cta>
      </Card>
    ))
  }

  function toSentenceCase(string) {
    const text = string.replace(/([A-Z])/g, " $1")
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  return (
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <Partners cta={cta} images={images} />
      <SecondaryWrapper>
        <Secondary id="featuredCharities">
          Featured charities in {toSentenceCase(loc.city)},{" "}
          {toSentenceCase(loc.province)}
        </Secondary>
        <SelectWrapper>
          <Secondary>Not your city?</Secondary>
          <LocationSelect color="#505050" fontSize="2.5rem" />
        </SelectWrapper>
      </SecondaryWrapper>
      {loading ? (
        <Content>
          {data && data.length > 0 ? renderData(data) : <Loading size="5" />}
        </Content>
      ) : (
        <Loading size="5" />
      )}
    </Container>
  )
}

export default Fundraising
