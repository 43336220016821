import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Cta from "../components/Cta"
import Charities from "../components/Charities"
import Steps from "../components/Steps"
import RecentBlogPosts from "../components/RecentBlogPosts"
import Benefits from "../components/Benefits"
import Fundraising from "../components/Fundraising"
import Stats from "../components/Stats"
import Ready from "../components/Ready"
import Review from "../components/Review"

export const IndexPageTemplate = ({
  title,
  subtitle,
  partnerImages,
  reviews,
  howItWorks,
  benefits,
  fundraising,
  statistics,
  isCms,
  recentBlogs,
}) => {
  return (
    <>
      <Hero title={title} subtitle={subtitle} />
      <Charities images={partnerImages} />
      <div
        style={{
          width: "95%",
          height: "2px",
          margin: "0 auto",
          background:
            "linear-gradient(to right,rgba(0, 0, 0, 0) 0%,rgba(200, 200, 200, 0.8) 50%,rgba(0, 0, 0, 0) 100%)",
        }}
      />
      <Steps data={howItWorks}>
        <Cta href="https://app.skipthedepot.com/register">
          Get started for free
        </Cta>
      </Steps>
      <Benefits data={benefits} />
      {isCms ? null : <Fundraising data={fundraising} images={partnerImages} />}
      <Stats data={statistics} />
      <Review reviews={reviews} />
      <RecentBlogPosts edges={recentBlogs} />
      <Ready svg>
        <Cta href="https://app.skipthedepot.com/register">Get Started</Cta>
        <Cta to="/contact" variant>
          Contact us
        </Cta>
      </Ready>
    </>
  )
}

const IndexPage = ({
  data: {
    allFile: { partnerImages },
    markdownRemark: {
      frontmatter: {
        title,
        subtitle,
        seoDesc,
        reviews,
        howItWorks,
        benefits,
        fundraising,
        statistics,
      },
    },
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Layout
      title="The Bottle Depot That Comes To You | SkipTheDepot | Bottle Pick up for Home, Business and Charity"
      seoDesc={seoDesc}
    >
      <IndexPageTemplate
        title={title}
        subtitle={subtitle}
        partnerImages={partnerImages}
        reviews={reviews}
        howItWorks={howItWorks}
        benefits={benefits}
        fundraising={fundraising}
        statistics={statistics}
        recentBlogs={edges}
      />
    </Layout>
  )
}

export default IndexPage

export const PageQuery = graphql`
  query IndexQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subtitle
        seoDesc
        reviews {
          author
          body
          url
        }
        howItWorks {
          title
          subtitle
          data {
            title
            image {
              relativePath
            }
            body
          }
        }
        benefits {
          title
          subtitle
          data {
            title
            body
            image {
              relativePath
            }
          }
        }
        fundraising {
          title
          subtitle
          cta
        }
        statistics {
          title
          subtitle
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM Do, YYYY")
            headingImage {
              childImageSharp {
                fluid(maxWidth: 275, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 100)
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "partners" } }) {
      partnerImages: edges {
        node {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
