import React, { useEffect } from "react"
import styled from "@emotion/styled"

const Container = styled.section`
  max-width: 130rem;
  margin: 6rem auto;
  padding: 0 1.5rem;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 52em) {
    flex-direction: column;
    align-items: center;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 30%;
  height: 26rem;
  overflow: hidden;
  @media only screen and (max-width: 68.75em) {
    height: 28rem;
  }
  @media only screen and (max-width: 56.25em) {
    height: 35rem;
  }
  @media only screen and (max-width: 52em) {
    width: 100%;
    height: 25rem;
  }
`
const Box = styled.div`
  padding: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  transform: translateY(0);
  z-index: 10;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-weight: 400;
  font-size: 1.9rem;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  color: #505050;
  @media only screen and (max-width: 68.75em) {
    font-size: 2rem;
  }
`

const Svg = styled.img`
  transition: all 0.3s;
  opacity: 1;
`

const Body = styled.p`
  padding: 1.5rem 0;
  font-weight: 400;
  color: #505050;
  opacity: 0;
  transition: all 0.3s;
`

const Steps = ({ data: { title, subtitle, data }, children }) => {
  function handleScroll(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.children[0].style.transform = "translateY(-17rem)"
        entry.target.children[0].children[0].style.opacity = "0"
        entry.target.children[0].children[2].style.opacity = "1"
      } else {
        entry.target.children[0].style.transform = "translateY(0)"
        entry.target.children[0].children[0].style.opacity = "1"
        entry.target.children[0].children[2].style.opacity = "0"
      }
    })
  }

  useEffect(() => {
    if (typeof window !== "undefined" && "IntersectionObserver" in window) {
      const observer = new IntersectionObserver(handleScroll, {
        root: null,
        rootMargin: "-10px",
        threshold: 1.0,
      })
      const ele = document.querySelectorAll(".stepsWrapper")
      ele.forEach((i) => observer.observe(i))
    }
  }, [])
  return (
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <Content>
        {data.map(({ image, title, body }, count) => (
          <Wrapper key={count} className="stepsWrapper">
            <Box id={`${count}-box`} key={count}>
              {image && (
                <Svg
                  id={`${count}-svg`}
                  src={
                    image.relativePath ? `/img/${image.relativePath}` : image
                  }
                  width={136}
                  height={136}
                  alt={`Step ${count} image`}
                  loading="lazy"
                />
              )}
              <Secondary>{title}</Secondary>
              <Body id={`${count}-body`}>{body}</Body>
            </Box>
          </Wrapper>
        ))}
      </Content>
      {children}
    </Container>
  )
}

export default Steps
