import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"

const fadein = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`

const Svg = styled.svg`
  #phone {
    opacity: 0;
    animation: ${fadein} 0.5s linear forwards;
  }
  #truck {
    opacity: 0;
    animation: ${fadein} 0.25s linear 0.5s forwards;
  }
  #dot-1 {
    opacity: 0;
    animation: ${fadein} 0.25s linear 0.75s forwards;
  }
  #dot-2 {
    opacity: 0;
    animation: ${fadein} 0.25s linear 1s forwards;
  }
  #dot-3 {
    opacity: 0;
    animation: ${fadein} 0.25s linear 1.25s forwards;
  }
  #house {
    opacity: 0;
    animation: ${fadein} 0.25s linear 1.5s forwards;
  }
`

const HeroSvg = () => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514.496 341.655">
      <defs>
        <filter
          id="d"
          x="-.026"
          width="1.053"
          y="-.056"
          height="1.112"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur stdDeviation="1.056" />
        </filter>
        <filter
          id="j"
          x="-.208"
          width="1.416"
          y="-.361"
          height="1.722"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur stdDeviation="1.059" />
        </filter>
        <filter
          id="i"
          x="-.208"
          width="1.416"
          y="-.361"
          height="1.722"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur stdDeviation="1.059" />
        </filter>
        <filter
          id="h"
          x="-.208"
          width="1.416"
          y="-.361"
          height="1.722"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur stdDeviation="1.059" />
        </filter>
        <filter
          id="e"
          x="-.028"
          width="1.057"
          y="-.049"
          height="1.098"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur stdDeviation="1.347" />
        </filter>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-120.001 2046.543 -544.282) scale(277.522)"
          spreadMethod="pad"
          id="a"
        >
          <stop offset="0" stopColor="#2dc7ff" />
          <stop offset="1" stopColor="#1976d2" />
        </linearGradient>
        <linearGradient
          xlinkHref="#a"
          id="b"
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(1.34759) rotate(-30 18.704 204.725)"
          x1="35.813"
          y1="222.303"
          x2="381.563"
          y2="222.303"
        />
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(97.505 -97.505) rotate(60 58.769 7.422)"
          spreadMethod="pad"
          id="g"
        >
          <stop offset="0" stopColor="#bdb9a7" />
          <stop offset=".461" stopColor="#f1eee2" />
          <stop offset=".915" stopColor="#f1eee2" />
          <stop offset="1" stopColor="#d4cfbd" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="c">
          <path d="M3734.27 1394.67c-3.76 0-6.47 1.96-6.99 5.65-.86 6.15 4.71 14.79 12.43 19.3l136.25 79.53c2.54 1.49 5 2.34 7.19 2.59.47.06.93.09 1.37.09 3.65 0 6.32-1.85 6.94-5.34-.55 3.08-2.73 4.82-5.73 5.2l.33-.97c.86-6.15-4.71-14.79-12.43-19.3l-136.25-79.53c-3.07-1.8-6-2.63-8.49-2.63-.46 0-.9.02-1.33.08.85-3.04 3.39-4.65 6.78-4.65 2.35 0 5.1.77 7.99 2.37-2.91-1.61-5.69-2.39-8.06-2.39m8.09 2.41c.01.01.03.01.04.02-.01-.01-.03-.01-.04-.02m136.72 79.8c7.18 4.19 12.5 11.95 12.52 17.97-.02-6.02-5.34-13.78-12.52-17.97m12.39 19.54c0 .02-.01.04-.01.06 0-.02.01-.04.01-.06" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="f">
          <path d="M3570.86 4602.69l-34.69 20.03c-2.03 1.17-3.87 1.29-5.2.52s-97.08-56.05-97.08-56.05c1.34.79 3.19.69 5.24-.49l34.69-20.03c4.07-2.35 7.36-8.05 7.36-12.74v-48.51c0-2.36-.83-4.01-2.18-4.77 0 0 95.73 55.26 97.06 56.03 1.33.77 2.16 2.42 2.16 4.77v48.51c0 4.68-3.3 10.39-7.36 12.73z" />
        </clipPath>
      </defs>
      <g transform="translate(-2.28)" id="phone">
        <rect
          rx="23.224"
          transform="scale(1.22475 .7071) rotate(-45)"
          y="235.757"
          x="-249.332"
          height="211.802"
          width="409.433"
          fillOpacity=".318"
        />
        <path
          d="M43.64 240.046l59.86 34.56-1.88 3.26-59.86-34.56z"
          fill="#303030"
        />
        <path
          d="M495.28 111.4c-.206 4.658-2.616 9.23-7.23 11.894l-79.28 45.769v.027l-.772.446v-.025L189.644 295.578a41.412 41.412 1.346 01-20.72 5.553 41.415 41.415 0 01-20.721-5.553L15.07 218.713c-4.74-2.736-7.154-7.484-7.243-12.27l-.003.002s-.008 8.943 0 13.832c.01 4.87 2.425 9.735 7.244 12.518l133.134 76.865a41.415 41.415 0 0020.72 5.553 41.41 41.41 0 0020.724-5.553l218.351-126.064.001-.006.772-.446v.004l79.28-45.772c4.827-2.788 7.244-7.665 7.243-12.544l-.002-13.42s.002-.004-.013-.01zM63.681 251.955a.955.54 6.949 01.211.014c.336.04.718.17 1.11.4l21.038 12.28c1.191.696 2.053 2.031 1.92 2.982-.08.57-.5.873-1.08.873-.367 0-.797-.123-1.245-.372l-.004-.003h-.005l-.068-.04-21.035-12.28c-1.11-.648-1.932-1.846-1.936-2.774l.004-.118.01-.088.004-.012v-.023l.002-.005.004-.01c.094-.54.507-.827 1.07-.826z"
          fill="#202020"
        />
        <path
          d="M334.13 23.636v.003a33.643 33.643 0 00-16.797 4.5L237.62 74.162v-.01l-.77.445v.01l-5.914 3.416h.002L108.23 148.871l-.003-.002-89.292 51.553c-2.992 1.727-3.316 4.591-3.318 5.744.003 1.15.327 4.013 3.318 5.74l133.131 76.863a33.649 33.649 0 0016.796 4.498 33.631 33.631 0 0016.791-4.498l257.632-148.744v.004l40.772-23.54c2.995-1.729 3.315-4.592 3.316-5.742 0-1.15-.324-4.01-3.314-5.736l-7.146-4.126-.012.025-32.387-18.698-.01-.006a11.926 11.926 0 00-11.181.398l-3.385 1.954a9.407 9.407 24.465 01-4.7 1.26 9.413 9.413 87.56 01-3.178-.554l-.028-.01a.934.528 6.949 01-.087-.033l-.048-.018c-.026-.008-.05-.02-.077-.032a9.28 9.28 5.138 01-1.086-.503c-.056-.03-.11-.061-.166-.093l-.031-.018-58.55-33.805-.087-.05a2.736 2.736 0 01-1.295-2.346c0-.93.46-1.86 1.38-2.393l5.327-3.075a3.025 3.025 11.324 001.526-2.642 3.02 3.02.962 00-1.304-2.482l-14.258-8.23.014-.026-2.378-1.373a33.626 33.626 0 00-16.78-4.493z"
          fill="url(#b)"
        />
        <path
          d="M334.2 15.824a41.427 41.427 0 00-20.727 5.555L237.69 65.133v.008l-.77.443v-.003l-77.447 44.71-.002.02-51.155 29.534-.016-.01-93.228 53.826c-4.92 2.841-7.333 7.853-7.24 12.823.09 4.788 2.5 9.535 7.24 12.27l133.134 76.863a41.427 41.427 0 0020.723 5.554 41.419 41.419 3.22 0020.723-5.553l298.4-172.282c4.614-2.664 7.023-7.236 7.23-11.893.226-5.092-2.181-10.288-7.226-13.2l-7.147-4.126.012-.016-123.622-71.37-.013.014-2.374-1.37a41.398 41.398 0 00-20.712-5.55zm0 7.86a33.635 33.635 0 0116.784 4.493l2.377 1.373-.017.026 14.258 8.231a3.01 3.01 0 011.303 2.481 3.02 3.02.962 01-1.526 2.64l-5.325 3.077a2.737 2.737 0 00-1.382 2.393c0 .902.431 1.807 1.297 2.345l.086.05 58.549 33.806.032.017a9.274 9.274 4.437 001.254.597l.075.032.049.018.087.032.029.01a9.4 9.4 4.658 007.88-.706l3.382-1.953a11.926 11.926 0 0111.183-.4l.01.003 32.385 18.698.012-.025 7.146 4.126c2.993 1.728 3.314 4.587 3.314 5.738 0 1.15-.322 4.013-3.316 5.744l-40.77 23.538v-.004l-100.998 58.311v.004l-156.635 90.434a33.622 33.622 0 01-16.79 4.499 33.627 33.627 3.107 01-16.794-4.498L19.007 211.95c-2.992-1.726-3.317-4.59-3.317-5.74 0-1.151.325-4.015 3.316-5.743l89.293-51.554.003.003 122.707-70.844-.002-.003 5.916-3.415v-.01l.767-.444.001.008 79.716-46.026a33.643 33.643 0 0116.796-4.498z"
          fill="#202020"
        />
        <g
          clipPath="url(#c)"
          transform="matrix(-.15441 0 0 -.1544 663.466 483.841)"
          fill="#505050"
        >
          <path d="M3734.27 1394.67c-3.76 0-6.47 1.96-6.99 5.65-.86 6.15 4.71 14.79 12.43 19.3l136.25 79.53c2.54 1.49 5 2.34 7.19 2.59.47.06.93.09 1.37.09 3.65 0 6.32-1.85 6.94-5.34-.55 3.08-2.73 4.82-5.73 5.2l.33-.97c.86-6.15-4.71-14.79-12.43-19.3l-136.25-79.53c-3.07-1.8-6-2.63-8.49-2.63-.46 0-.9.02-1.33.08.85-3.04 3.39-4.65 6.78-4.65 2.35 0 5.1.77 7.99 2.37-2.91-1.61-5.69-2.39-8.06-2.39m8.09 2.41c.01.01.03.01.04.02-.01-.01-.03-.01-.04-.02m136.72 79.8c7.18 4.19 12.5 11.95 12.52 17.97-.02-6.02-5.34-13.78-12.52-17.97m12.39 19.54c0 .02-.01.04-.01.06 0-.02.01-.04.01-.06" />
        </g>
        <g fill="#fff" fillOpacity=".169" strokeWidth=".75">
          <path d="M261.171 171.248l.093.053-9.849 5.779 18.032 10.41 7.44-21.68 36.447 6.8-5.817 17.277a18.198 10.296 6.95 01-13.17 7.163l-41.23 3.56a13.72 7.762 6.95 01-11.099-2.46l-13.28-7.736-9.333 4.813-.36-.207 6.698-19.444zm-32.192-17.626l-.142-.012-5.015-8.524-22.515 2.542 30.04 17.343-23.874 14.172-23.991-13.735a18.168 10.278 6.95 01-5.92-11.131l9.933-23.078a13.627 7.71 6.95 017.889-4.06l16.758-1.906-3.79-7.734.422-.07 26.959 15.565zm38.733-4.19l.091-.053 14.824 2.768 4.508-12.926-37.55 4.377-12.433-20.971 29.808-3.543a18.23 10.313 6.95 0119.112 3.983l31.162 19.45a13.658 7.727 6.95 013.238 6.502l-3.418 9.607 13.103 2.933-.041.265-33.71 3.897z" />
          <path d="M260.992 171.283l.105.061-9.768 5.732 17.912 10.412s15.715 10.357 27.661 9.264l-43.964 3.893a13.658 7.727 6.95 01-11.087-2.452l-13.28-7.737-9.355 4.813-.348-.2 6.708-19.45zm-32.119-17.64l-.08-.047-5.025-8.518-22.515 2.554s-21.554 1.453-24.425 8.18l10.6-24.615a13.75 7.779 6.95 017.891-4.06l16.757-1.907-3.8-7.728.433-.075 26.96 15.565zm38.84-4.211l.09-.053 14.824 2.768 4.592-12.947s5.799-11.8-3.28-17.457l34.517 20.322c2.515 1.637 2.666 5.013 2.003 6.947l-3.416 9.608 13.114 2.94-.054.257-33.696 3.904z" />
        </g>
      </g>
      <g id="house">
        <g
          transform="matrix(1.5104 0 0 -1.5104 -163.982 347.56)"
          filter="url(#d)"
          fillOpacity=".349"
        >
          <path d="M177.886 86.607l-24.778-14.305 22.144-12.785 13.023 7.52-17.593 10.156 11.754 6.787z" />
          <path d="M170.682 77.194l28.89 16.68 49.2-28.406-28.89-16.68z" />
        </g>
        <g strokeWidth=".236">
          <path
            d="M108.865 230.759l-.074-.033.043.018-.053-.035-.023-.015-.004-.003-.002-.002-.175-.023-.066.164.058.125.005.027.14.139.172-.036.078-.155-.098-.171M82.322 215.565l-.076-.033.044.018-.054-.034-.021-.015-.005-.004-.002-.002-.174-.023-.067.164.058.125.005.028.139.14.173-.037.078-.156-.098-.171"
            fill="#31b241"
          />
          <path
            d="M99.594 178.849l-.01 20.542 67.152 38.77v-20.548L99.594 178.85"
            fill="#232b35"
          />
          <path
            d="M166.736 217.614v20.546l31.632-18.275v-20.548l-31.632 18.276"
            fill="#414b5d"
          />
          <path
            d="M156.739 210.04v14.992l5.734 3.312v-14.992zm.589 1.022l4.555 2.63v13.63l-4.555-2.63v-13.63"
            fill="#c9c9c9"
          />
          <path
            d="M157.328 224.692l4.555 2.63v-.681l-3.965-2.29-.59.34"
            fill="#99a275"
          />
          <path
            d="M157.328 211.062v13.63l.59-.34v-12.95l-.59-.34"
            fill="#b9c19d"
          />
          <path
            d="M157.918 211.403v12.948l3.965 2.29v-12.949l-3.965-2.29"
            fill="#6cceda"
          />
          <path
            d="M158.954 216.428V212l2.93 1.692zM161.883 214.349l-2.495 2.33.435.25 2.06-1.898v-.682"
            fill="#bae8ec"
          />
          <path
            d="M158.508 224.011v-12.268l-.59-.34v12.948l3.965 2.29v-.68l-3.375-1.95"
            fill="#3e9cc2"
          />
          <path
            d="M161.883 218.12l-3.965-2.29-.355.203 4.32 2.494v-.407"
            fill="#b9c19d"
          />
          <path
            d="M161.883 218.527l-4.32-2.494v.363l4.32 2.494v-.363"
            fill="#ebede5"
          />
          <path
            d="M146.725 204.294v14.993l5.736 3.311v-14.992zm.59 1.022l4.555 2.63v13.63l-4.555-2.63v-13.63"
            fill="#c9c9c9"
          />
          <path
            d="M147.315 218.946l4.555 2.63v-.681l-3.965-2.29-.59.34"
            fill="#99a275"
          />
          <path
            d="M147.315 205.316v13.63l.59-.34v-12.949l-.59-.34"
            fill="#b9c19d"
          />
          <path
            d="M147.905 205.657v12.948l3.965 2.29v-12.948l-3.965-2.29"
            fill="#6cceda"
          />
          <path
            d="M148.94 210.683v-4.428l2.93 1.692zM151.87 208.604l-2.495 2.33.435.25 2.06-1.9v-.68"
            fill="#bae8ec"
          />
          <path
            d="M148.496 218.265v-12.267l-.59-.34v12.947l3.964 2.29v-.682l-3.374-1.949"
            fill="#3e9cc2"
          />
          <path
            d="M151.87 212.374l-3.965-2.29-.354.203 4.32 2.494v-.407"
            fill="#b9c19d"
          />
          <path
            d="M151.87 212.782l-4.32-2.494v.362l4.32 2.494v-.362"
            fill="#ebede5"
          />
          <path
            d="M131.117 193.818v23.776l11.39 6.568v-23.777l-11.39-6.567"
            fill="#c9c9c9"
          />
          <path
            d="M132.615 202.982l3.562 2.057v-.532l-3.1-1.792-.462.267"
            fill="#c1dcde"
          />
          <path
            d="M132.616 198.039v4.943l.461-.267v-4.41l-.46-.266"
            fill="#7fadb5"
          />
          <path
            d="M133.078 198.306l-.001 4.409 3.1 1.791.002-4.41-3.101-1.79"
            fill="#6cceda"
          />
          <path
            d="M133.887 202.235v-3.461l2.292 1.323zM136.179 200.61l-1.953 1.823.34.195 1.613-1.485v-.533"
            fill="#bae8ec"
          />
          <path
            d="M133.539 202.45v-3.878l-.461-.266-.001 4.409 3.1 1.791v-.532l-2.638-1.525"
            fill="#3e9cc2"
          />
          <path
            d="M137.448 205.774l3.561 2.057.001-.532-3.1-1.792-.462.267"
            fill="#c1dcde"
          />
          <path
            d="M137.449 200.83v4.944l.46-.267.002-4.41-.462-.267"
            fill="#7fadb5"
          />
          <path
            d="M137.91 201.098v4.409l3.1 1.791.001-4.41-3.1-1.79"
            fill="#6cceda"
          />
          <path
            d="M138.718 205.027l.001-3.462 2.292 1.324zM141.01 203.402l-1.95 1.821.339.196 1.611-1.485v-.532"
            fill="#bae8ec"
          />
          <path
            d="M138.37 205.241l.001-3.877-.46-.266-.001 4.409 3.1 1.791v-.532l-2.64-1.525"
            fill="#3e9cc2"
          />
          <path
            d="M132.615 209.45l3.562 2.057v-.533l-3.1-1.79-.462.266"
            fill="#c1dcde"
          />
          <path
            d="M132.616 204.507v4.942l.461-.266v-4.41l-.46-.266"
            fill="#7fadb5"
          />
          <path
            d="M133.078 204.773l-.001 4.41 3.1 1.791.002-4.41-3.101-1.79"
            fill="#6cceda"
          />
          <path
            d="M133.887 208.703v-3.463l2.292 1.324zM136.179 207.078l-1.953 1.82.34.198 1.613-1.485v-.533"
            fill="#bae8ec"
          />
          <path
            d="M133.539 208.917v-3.877l-.461-.267-.001 4.41 3.1 1.791v-.532l-2.638-1.525"
            fill="#3e9cc2"
          />
          <path
            d="M137.448 212.24l3.561 2.059.001-.533-3.1-1.791-.462.266"
            fill="#c1dcde"
          />
          <path
            d="M137.449 207.299v4.942l.46-.266.002-4.41-.462-.266"
            fill="#7fadb5"
          />
          <path
            d="M137.91 207.565v4.41l3.1 1.791.001-4.41-3.1-1.79"
            fill="#6cceda"
          />
          <path
            d="M138.718 211.495l.001-3.463 2.292 1.323zM141.01 209.87l-1.95 1.821.339.196 1.611-1.485v-.532"
            fill="#bae8ec"
          />
          <path
            d="M138.37 211.708l.001-3.877-.46-.266-.001 4.41 3.1 1.791v-.534l-2.64-1.524"
            fill="#3e9cc2"
          />
          <path
            d="M94.866 157.075l-.01 24.489 67.151 38.77v-24.495l-67.14-38.764"
            fill="#8f8f8f"
          />
          <path
            d="M162.007 195.838v24.495l36.36-20.996v-31.67l-36.36 28.172"
            fill="#dfdfdf"
          />
          <path
            d="M159.683 197.64v-1.556l-67.152-38.77v1.554l67.152 38.771"
            fill="#414b5d"
          />
          <path
            d="M159.683 196.084l38.685-29.973-67.141-38.764-38.696 29.967 67.152 38.77"
            fill="#232b35"
          />
          <path
            d="M198.368 166.11v1.556l-38.685 29.973v-1.555l38.685-29.973"
            fill="#232b35"
          />
          <path
            d="M146.99 192.556v16.206l6.2 3.58v-16.207zm.637 1.104l4.924 2.843v14.735l-4.924-2.843V193.66"
            fill="#414b5d"
          />
          <path
            d="M147.627 208.395l4.924 2.843v-.736l-4.286-2.475-.638.367"
            fill="#2d3645"
          />
          <path
            d="M147.627 193.66v14.735l.638-.367v-13.999l-.638-.369"
            fill="#232b35"
          />
          <path
            d="M148.265 194.029v13.998l4.287 2.475v-13.998l-4.287-2.475"
            fill="#6cceda"
          />
          <path
            d="M149.384 199.462v-4.787l3.168 1.829zM152.552 197.214l-2.698 2.519.47.27 2.228-2.052v-.737"
            fill="#bae8ec"
          />
          <path
            d="M148.903 207.659v-13.262l-.638-.368v13.998l4.287 2.475v-.737l-3.65-2.106"
            fill="#3e9cc2"
          />
          <path
            d="M152.552 201.29l-4.287-2.475-.383.219 4.67 2.697v-.44"
            fill="#232b35"
          />
          <path
            d="M152.552 201.73l-4.67-2.696v.393l4.67 2.696v-.392M133.713 184.897v16.206l6.2 3.58v-16.207zm.638 1.105l4.925 2.842v14.735l-4.925-2.843v-14.733"
            fill="#414b5d"
          />
          <path
            d="M134.35 200.735l4.926 2.843v-.736l-4.288-2.474-.637.367"
            fill="#2d3645"
          />
          <path
            d="M134.35 186.002v14.733l.638-.367V186.37l-.637-.367"
            fill="#232b35"
          />
          <path
            d="M134.988 186.37v13.998l4.288 2.474v-13.998l-4.288-2.474"
            fill="#6cceda"
          />
          <path
            d="M136.108 191.802v-4.785l3.168 1.827zM139.276 189.555l-2.698 2.518.47.272 2.228-2.053v-.737"
            fill="#bae8ec"
          />
          <path
            d="M135.627 200v-13.262l-.639-.368v13.998l4.288 2.474v-.736l-3.65-2.107"
            fill="#3e9cc2"
          />
          <path
            d="M139.276 193.631l-4.288-2.474-.383.218 4.67 2.697v-.441"
            fill="#232b35"
          />
          <path
            d="M139.276 194.072l-4.671-2.697v.393l4.67 2.696v-.392M177.543 191.574l-6.2 3.58v16.207l6.2-3.58zm-.638 1.106v14.733l-4.924 2.843v-14.734l4.924-2.842"
            fill="#414b5d"
          />
          <path
            d="M176.905 207.413l-4.924 2.843v-.736l4.286-2.474.638.367"
            fill="#2d3645"
          />
          <path
            d="M176.905 192.68v14.733l-.638-.367v-13.999l.638-.367"
            fill="#232b35"
          />
          <path
            d="M176.267 193.047v13.999l-4.287 2.474v-13.998l4.287-2.475"
            fill="#6cceda"
          />
          <path
            d="M175.149 198.48v-4.785l-3.169 1.827zM171.98 196.232l2.698 2.52-.469.271-2.229-2.054v-.737"
            fill="#bae8ec"
          />
          <path
            d="M175.63 206.677v-13.26l.637-.37v13.999l-4.287 2.474v-.737l3.65-2.106"
            fill="#3e9cc2"
          />
          <path
            d="M171.98 200.309l4.287-2.475.384.219-4.67 2.696v-.44"
            fill="#232b35"
          />
          <path
            d="M171.98 200.75l4.671-2.697v.393l-4.67 2.696v-.393M190.82 183.916l-6.2 3.58v16.207l6.2-3.58zm-.638 1.105v14.734l-4.925 2.842v-14.733l4.925-2.843"
            fill="#414b5d"
          />
          <path
            d="M190.182 199.755l-4.925 2.842v-.736l4.287-2.474.638.368"
            fill="#2d3645"
          />
          <path
            d="M190.182 185.02v14.735l-.638-.368V185.39l.638-.37"
            fill="#232b35"
          />
          <path
            d="M189.544 185.39v13.997l-4.287 2.474v-13.997l4.287-2.474"
            fill="#6cceda"
          />
          <path
            d="M188.424 190.821v-4.786l-3.167 1.829zM185.257 188.573l2.698 2.52-.47.271-2.228-2.053v-.738"
            fill="#bae8ec"
          />
          <path
            d="M188.906 199.018v-13.261l.638-.367v13.997l-4.287 2.474v-.737l3.649-2.106"
            fill="#3e9cc2"
          />
          <path
            d="M185.257 192.65l4.287-2.475.384.219-4.67 2.696v-.44"
            fill="#232b35"
          />
          <path
            d="M185.257 193.09l4.67-2.696v.393l-4.67 2.696v-.393"
            fill="#414b5d"
          />
          <path
            d="M80.088 184.643l-.01 5.467.01.007-.01 20.541 26.522 15.285v-26.022l-26.512-15.278"
            fill="#232b35"
          />
          <path
            d="M94.845 176.125l-14.768 8.51 26.523 15.286 14.756-8.518-26.511-15.278"
            fill="#414b5d"
          />
          <path
            d="M117.766 191.539L94.845 178.33l-11.175 6.441 1.219.703 9.956-5.739 21.704 12.508 1.217-.704"
            fill="#232b35"
          />
          <path
            d="M121.356 196.87v-5.467L106.6 199.92v26.022l19.508-11.268v-15.069l-4.75-2.735"
            fill="#414b5d"
          />
          <path
            d="M95.65 198.77v16.208l6.2 3.58V202.35zm.638 1.105l4.925 2.843v14.735l-4.925-2.843v-14.735"
            fill="#ebede5"
          />
          <path
            d="M96.288 214.61l4.925 2.842v-.735l-4.287-2.475-.638.367"
            fill="#99a275"
          />
          <path
            d="M96.288 199.875v14.734l.638-.367v-13.998l-.638-.369"
            fill="#b9c19d"
          />
          <path
            d="M96.926 200.244v13.998l4.287 2.475v-13.998l-4.287-2.475"
            fill="#6cceda"
          />
          <path
            d="M98.046 205.677v-4.788l3.167 1.83zM101.213 203.429l-2.698 2.518.47.271 2.228-2.052v-.737"
            fill="#bae8ec"
          />
          <path
            d="M97.564 213.873v-13.261l-.638-.368v13.998l4.287 2.475v-.738l-3.65-2.106"
            fill="#3e9cc2"
          />
          <path
            d="M101.213 207.505l-4.287-2.475-.383.219 4.67 2.697v-.44"
            fill="#b9c19d"
          />
          <path
            d="M101.213 207.946l-4.67-2.697v.392l4.67 2.696v-.391M84.826 192.56v16.206l6.2 3.58V196.14zm.638 1.105l4.925 2.843v14.733l-4.925-2.842v-14.734"
            fill="#ebede5"
          />
          <path
            d="M85.464 208.399l4.925 2.842v-.736l-4.287-2.475-.638.369"
            fill="#99a275"
          />
          <path
            d="M85.464 193.665v14.734l.638-.37v-13.996l-.638-.368"
            fill="#b9c19d"
          />
          <path
            d="M86.102 194.033v13.997l4.287 2.475v-13.997l-4.287-2.475"
            fill="#6cceda"
          />
          <path
            d="M87.221 199.465v-4.786l3.168 1.829zM90.39 197.217l-2.699 2.52.47.27 2.228-2.053v-.737"
            fill="#bae8ec"
          />
          <path
            d="M86.74 207.662V194.4l-.638-.368v13.996l4.287 2.475v-.736l-3.649-2.107"
            fill="#3e9cc2"
          />
          <path
            d="M90.39 201.294l-4.288-2.475-.384.218 4.671 2.697v-.44"
            fill="#b9c19d"
          />
          <path
            d="M90.39 201.734l-4.672-2.697v.393l4.671 2.697v-.393"
            fill="#ebede5"
          />
          <path
            d="M98.893 174.074l.014-8.117 23.167 13.242v8.125l-23.18-13.25"
            fill="#414b5d"
          />
          <path
            d="M100.33 173.24l.01-5.638-.717-.41-.01 6.466 21.743 12.429v-.829L100.33 173.24"
            fill="#232b35"
          />
          <path
            d="M100.339 167.602l-.009 5.637 21.027 12.02v-5.643l-21.018-12.014"
            fill="#6cceda"
          />
          <path
            d="M121.356 184.43l-20.31-11.61.01-4.809-.717-.409-.009 5.637 21.027 12.02v-.83"
            fill="#3e9cc2"
          />
          <path
            d="M102.07 172.774v-4.193l19.286 11.035-2.79 2.607-16.496-9.449M121.356 180.241l-2.377 2.22.415.24 1.962-1.81v-.65"
            fill="#bae8ec"
          />
        </g>
      </g>
      <g id="truck">
        <path
          transform="matrix(1.1626 0 0 -1.1626 242.06 385.861)"
          d="M116.496 264.57l37.935-21.902-75.952-43.852-37.936 21.902 75.953 43.852"
          fillOpacity=".337"
          filter="url(#e)"
        />
        <path
          d="M404.548 73.622l-26.783-15.465c-.584-.337-1.528-.337-2.111 0l-.995.576 1.098.635.952-.551 26.698 15.414-.953.55 1.1.634.994-.574c.294-.17.44-.392.438-.613 0-.22-.146-.438-.438-.606"
          fill="#4b4647"
        />
        <path
          d="M374.659 58.733v1.75l1.098.633v-1.748l-1.098-.635"
          fill="#373132"
        />
        <path
          d="M376.71 58.817l-.953.55v1.75l.952-.55 25.746 14.864v-.65l.952-.55-26.698-15.414"
          fill="#1b1817"
        />
        <path
          d="M402.455 74.78v1.75l1.099.635v-1.75l-1.099-.635"
          fill="#373132"
        />
        <path
          d="M404.548 74.84l-.994.575v1.75l.994-.576c.292-.168.438-.388.438-.609v-1.752c0 .22-.145.442-.438.613"
          fill="#1b1817"
        />
        <path
          d="M324.68 93.38l58.166-33.583 12.423 7.173-58.168 33.582-12.421-7.171"
          fill="#373132"
        />
        <path
          d="M337.1 100.552l58.17-33.583v4.065l-58.17 33.583v-4.065"
          fill="#1b1817"
        />
        <path
          d="M394.025 73.944l-.59.34.65 1.833 6.116 3.531-1.616-4.56c-.663-1.876-2.495-2.335-4.56-1.144"
          fill="#100f0d"
        />
        <path
          d="M381.38 77.827c1.073-1.856 2.48-3.287 3.89-4.104.882-.508 1.767-.775 2.568-.753.283.01.566.015.848.02h.005a2.99 2.99 0 011.408.381l2.702 1.57c-1.14-.647-2.628-.441-4.118.42-1.487.857-2.972 2.369-4.104 4.329-2.263 3.92-2.252 8.15.027 9.448l-2.702-1.57c-.966-.555-1.43-1.657-1.511-1.792-1.07-1.767-.743-4.954.988-7.95"
          fill="#1b1918"
        />
        <path
          d="M384.579 79.691c1.131-1.96 2.618-3.472 4.105-4.33 1.488-.86 2.979-1.066 4.118-.418.766.44 1.393 1.59 1.635 2.659.149.667-7.348 11.736-7.348 11.736-.857.18-2.059.047-2.482-.199-2.32-1.348-2.29-5.528-.028-9.448"
          fill="#100f0d"
        />
        <path
          d="M385.213 89.026c2.15 1.243 5.64-.771 7.791-4.499 2.151-3.727 2.151-7.754 0-8.996-2.152-1.244-5.64.771-7.791 4.497-2.152 3.727-2.152 7.755 0 8.998"
          fill="#100f0d"
        />
        <path
          d="M385.21 80.039c1.072-1.858 2.479-3.29 3.89-4.104.882-.508 1.764-.777 2.566-.756l.851.023h.003a2.994 2.994 0 011.407.38l2.703 1.571c-1.14-.649-2.63-.442-4.118.419-1.488.858-2.974 2.369-4.104 4.328-2.265 3.921-2.253 8.15.027 9.448l-2.7-1.571c-.967-.554-1.43-1.656-1.512-1.79-1.071-1.767-.743-4.952.987-7.95"
          fill="#1b1918"
        />
        <path
          d="M388.409 81.901c1.13-1.959 2.617-3.47 4.104-4.33 1.489-.859 2.978-1.066 4.119-.418.765.44 1.392 1.59 1.633 2.659.151.667-7.346 11.736-7.346 11.736-.86.18-2.06.048-2.485-.197-2.318-1.349-2.29-5.528-.023-9.45"
          fill="#262424"
        />
        <path
          d="M389.042 91.237c2.151 1.241 5.639-.772 7.79-4.5 2.153-3.726 2.153-7.754 0-8.996-2.15-1.244-5.638.772-7.79 4.498-2.152 3.727-2.152 7.755 0 8.997"
          fill="#1b1918"
        />
        <path
          d="M390.499 88.713c1.346.778 3.53-.484 4.877-2.816 1.349-2.333 1.349-4.854 0-5.633-1.347-.778-3.53.484-4.877 2.816-1.349 2.333-1.349 4.855 0 5.633"
          fill="#100f0d"
        />
        <path
          d="M389.492 86.292l1.614-.325a1.453 1.453 0 00.136.405l-.998 2.16c-.54-.464-.79-1.275-.752-2.24zm2.227 2.628l1-2.163c.145-.065.292-.15.439-.255l.997 1.011c-.783.793-1.653 1.294-2.436 1.407zm3.912-3.497l-.998-1.01c.063-.191.107-.38.135-.562l1.615-1.537c.038.92-.213 2.022-.752 3.109zm.19-4.788l-1.617 1.54a.945.945 0 00-.356-.094v-1.96c.56-.146 1.089-.11 1.528.143a1.686 1.686 0 01.444.372zm-5.323 2.445a7.765 7.765 0 011.527-1.907v1.96a4.278 4.278 0 00-.355.505l-1.616.326c.128-.295.275-.591.444-.884zm-.19 5.964c1.452.837 3.807-.52 5.259-3.037 1.453-2.517 1.453-5.235 0-6.074-1.452-.837-3.807.52-5.26 3.037-1.453 2.517-1.453 5.236 0 6.074"
          fill="#d0cfb2"
        />
        <path
          d="M391.545 82.856v-1.207c.156-.171.317-.33.48-.477v1.96a4.278 4.278 0 00-.355.506l-1.616.326c.06-.138.124-.276.193-.412l.941-.192c.11-.181.23-.35.357-.504M390.735 86.04l.37-.073a1.453 1.453 0 00.137.405l-.998 2.16a1.853 1.853 0 01-.39-.475l.908-1.962-.026-.054M396.383 82.314c.038.92-.213 2.022-.752 3.109l-.413-.418c.356-.754.574-1.51.653-2.204l.512-.487M393.674 87.234l-.64-.648.123-.083.998 1.009c-.783.794-1.653 1.295-2.436 1.407l.217-.471c.581-.233 1.182-.649 1.738-1.214M395.123 80.146l.253.118c.17.098.317.222.444.372l-1.616 1.538a.945.945 0 00-.356-.093v-.303l1.49-1.419-.214-.212"
          fill="#a9a891"
        />
        <path
          d="M392.61 85.058c.18.104.475-.066.656-.38.181-.313.181-.653 0-.759-.181-.104-.477.067-.657.38-.181.314-.181.654 0 .759M392.747 83.436c.105.06.276-.037.38-.219.104-.181.104-.378 0-.437-.105-.062-.275.036-.38.219-.104.181-.104.376 0 .437M393.683 84.87c-.147.059-.285.253-.308.434-.02.183.078.284.223.226.147-.059.283-.252.307-.434.021-.183-.078-.283-.222-.226M392.5 85.81c-.026-.156-.162-.19-.308-.08-.146.111-.244.327-.221.482.023.156.16.193.306.081.145-.111.245-.327.223-.482M391.922 84.924c.131-.154.185-.37.116-.484-.066-.113-.228-.081-.36.073-.132.153-.184.37-.116.483.067.114.227.081.36-.072M393.836 83.403c-.068.19-.015.347.117.349.133 0 .293-.152.36-.345.068-.19.016-.346-.116-.35-.132 0-.294.154-.36.346"
          fill="#201f1e"
        />
        <path
          d="M390.705 74.89l3.32-1.919c1.536-.886 2.965-.994 4.003-.394 0 0-5.522-3.192-6.13-3.539-1.037-.59-2.46-.48-3.987.404l-3.32 1.917c-2.426 1.4-4.581 4.428-5.359 7.527l-1.403 5.588a1.643 1.643 0 00.772 1.822l4.114 2.376a.809.809 0 001.189-.503l1.443-5.752c.78-3.1 2.934-6.129 5.358-7.528"
          fill="#414042"
        />
        <path
          d="M399.382 74.314c-.283-.806-.754-1.39-1.353-1.737-1.04-.6-2.468-.492-4.004.395l-3.32 1.917c-2.424 1.4-4.58 4.428-5.358 7.528l-1.443 5.752a.808.808 0 01-1.189.503c1.064.614 1.762.233 1.892-.287l1.538-6.115c.663-2.64 2.496-5.216 4.56-6.409l3.32-1.917c2.065-1.191 3.897-.732 4.56 1.143l1.616 4.561.886-.513-1.705-4.821"
          fill="#262424"
        />
        <path
          d="M380.398 83.072v-4.365l-.732.424v8.845l.732-.423v-3.59l1.75 1.01v-.89l-1.75-1.012"
          fill="#414042"
        />
        <path
          d="M380.398 87.551l-.732.425 1.75 1.009.732-.422-1.75-1.012"
          fill="#262424"
        />
        <path
          d="M381.416 85.396v-.89l-1.749-1.011v-4.364l-.884.509v8.09c0 .389.209.747.543.942l2.09 1.205v-.892l-1.749-1.01v-3.588l1.749 1.009"
          fill="#100f0d"
        />
        <path
          d="M367.875 90.302v-4.364l-.733.424v8.844l.733-.423v-3.59l1.75 1.012v-.891l-1.75-1.012"
          fill="#414042"
        />
        <path
          d="M367.875 94.783l-.733.423 1.75 1.01.732-.423-1.75-1.01"
          fill="#262424"
        />
        <path
          d="M368.892 92.626v-.89l-1.75-1.01v-4.364l-.883.508v8.092c0 .388.209.746.544.94l2.089 1.206v-.89l-1.75-1.012v-3.589l1.75 1.01"
          fill="#100f0d"
        />
        <path
          d="M363.651 97.533l18.432-10.646.688.398-18.433 10.645v2.426l-.686-.395v-2.428M383.482 81.401l-19.83 11.454v2.426l.686.396V93.25l19.83-11.451-.685-.398"
          fill="#2173ce"
        />
        <path
          d="M364.338 97.93l18.433-10.645-.725 2.848-17.707 10.223V97.93M384.169 81.798l-19.83 11.452v2.428l19.108-11.037.722-2.843"
          fill="#2196f3"
        />
        <path
          d="M348.183 104.923l3.766 2.174c.22.127.418.14.562.057.147-.081.237-.262.237-.517v-5.267c0-.51-.358-1.128-.8-1.383l-3.767-2.175c-.22-.127-.422-.14-.568-.053-.14.084-.23.262-.23.514v5.266c0 .51.359 1.128.8 1.384"
          fill="#b1ae9e"
        />
        <g
          clipPath="url(#f)"
          transform="matrix(.10856 0 0 -.10856 -25.192 593.604)"
        >
          <path
            d="M3570.86 4602.69l-34.69 20.03c-2.03 1.17-3.87 1.29-5.2.52s-97.08-56.05-97.08-56.05c1.34.79 3.19.69 5.24-.49l34.69-20.03c4.07-2.35 7.36-8.05 7.36-12.74v-48.51c0-2.36-.83-4.01-2.18-4.77 0 0 95.73 55.26 97.06 56.03 1.33.77 2.16 2.42 2.16 4.77v48.51c0 4.68-3.3 10.39-7.36 12.73"
            fill="url(#g)"
          />
        </g>
        <path
          d="M357.49 100.704c-.567-.216-.947-.874-.85-1.473a.9.9 0 01.427-.652l-.502.29a.905.905 0 00-.428.651c-.098.6.284 1.26.85 1.474a.905.905 0 00.779-.044l.505-.29a.906.906 0 01-.78.044"
          fill="#1c1817"
        />
        <path
          d="M356.137 99.52l.025-.112c-.1.058-.233.133-.4.226-.27.142-.542.41-.75.768-.386.672-.386 1.398 0 1.623.217.124.508.067.791-.125.423-.251 1.05-.656 1.358-.857l-.174-.049c-.566-.215-.948-.874-.85-1.474"
          fill="#bcb9a7"
        />
        <path
          d="M357.847 98.533a.903.903 0 00-.779.046.902.902 0 00-.429.652c-.096.6.284 1.257.851 1.473a.903.903 0 00.78-.044.898.898 0 00.427-.653c.098-.6-.282-1.259-.85-1.474"
          fill="#2e2929"
        />
        <path
          d="M381.139 8.436h-.926v9.967h.926V8.436M406.314 33.47h17.263v-.535h-17.264z"
          fill="#efefef"
        />
        <path d="M406.314 65.264h17.263V33.47h-17.264z" fill="#dfdfdf" />
        <path d="M406.314 65.264h2.802V33.47h-2.802z" fill="#afafaf" />
        <path
          d="M323.32 48.283v33.895l27.918 16.12V64.402l-27.919-16.12"
          fill="#8f8f8f"
        />
        <path
          d="M379.218 16.01l27.918 16.12-55.898 32.273-27.919-16.12 55.898-32.272"
          fill="#afafaf"
        />
        <path
          d="M407.143 32.111l-55.897 32.272v33.894l55.897-32.272V32.111"
          fill="#dfdfdf"
        />
        <g transform="matrix(.6244 -.3605 0 -.83254 47.527 505.2)">
          <circle
            transform="scale(1 -1)"
            r="17.103"
            cy="-298.51"
            cx="531.179"
            fill="#2196f3"
          />
          <g fill="#fff">
            <path
              d="M534.75 296.887h.022l.018-2.584h4.063l-3.392 5.853 5.433 3.21 2.717-4.65a3.085 3.085 0 00-.086-3.327l-3.951-6.167a2.325 2.325 0 00-1.73-.89l-3.007.016-.112-2.3h-.081l-3.04 5.254zm-7.066-.217l-.018-.016-2.23 1.269-2.04-3.503h6.77l.075-6.3-5.383-.026a3.08 3.08 0 00-2.84 1.739l-3.385 6.492a2.31 2.31 0 00.097 1.942l1.516 2.61-1.936 1.25.034.07h6.075zm3.546 5.984v.023l2.21 1.305-2.014 3.5-3.377-5.872-5.493 3.108 2.667 4.677a3.09 3.09 0 002.93 1.59l7.308-.33a2.315 2.315 0 001.633-1.044l1.49-2.61 2.05 1.045.046-.066-3.038-5.263z"
              fillOpacity=".65"
            />
            <path d="M534.738 296.855h.023l.019-2.563 4.05-.015s3.792-.29 4.924 1.51l-4.193-6.596a2.315 2.315 0 00-1.728-.89l-3.006.015-.115-2.301h-.078l-3.04 5.255zm-7.062-.203h-.018l-2.229 1.265-2.038-3.504s-2.145-3.132-1.156-5.022l-3.61 6.927a2.33 2.33 0 00.097 1.941l1.516 2.61-1.936 1.247.034.073h6.075zm3.554 6.002v.023l2.21 1.305-2.009 3.516s-1.649 3.413-3.776 3.507l7.855-.089c.603-.041 1.28-.783 1.582-1.305l1.49-2.61 2.051 1.045.045-.066-3.036-5.263z" />
          </g>
        </g>
        <path
          d="M299.035 92.061l-.101-.028-2.174-1.255c-.603-.35-1.076-1.168-1.076-1.865V87.42a.2.2 0 01.2-.201.201.201 0 01.203.201v1.492c0 .547.4 1.241.876 1.516l2.174 1.255.073.276-.174.1"
          fill="#262424"
        />
        <path
          d="M295.848 88.763l-1.214-.701c-.327-.19-.592-.698-.592-1.134v-4.205c0-.436.265-.637.592-.449l2.084 1.204c.329.19.594.697.594 1.133v3.044c0 1.079-.656 1.574-1.463 1.108"
          fill="#c8c7c0"
        />
        <path
          d="M297.313 87.656v-3.045c0-.436-.267-.944-.595-1.133l-2.084-1.204c-.316-.182-.574 0-.592.407.027-.659.659-1.178 1.132-1.182l.244.07 2.085 1.203c.327.19.593.697.593 1.135v3.297c0 .228-.03.43-.084.602-.277.895-1.13 1.238-1.715 1.122.59.086 1.015-.404 1.015-1.273"
          fill="#8c8984"
        />
        <path
          d="M339.762 80.148c-.01-.312-.25-.427-.524-.267l-9.835 5.676a.64.64 0 00-.282.397 595.553 595.553 0 01-3.145 12.971c-.264 1.05-.407 2.085-.405 3.044a234.59 234.59 0 01-.017 3.59c-.008.323.213.449.479.294l2.708-1.563c.247-.144.484-.427.63-.767a766.031 766.031 0 001.928-4.525c.228-.532.622-1.013 1.044-1.256l7.364-4.252a.683.683 0 00.312-.54 327.547 327.547 0 00-.256-12.802m5.11-4.154s.686 8.013.686 24.302l-7.744 4.471c-2.803 1.618-5.672 4.91-6.528 8.485l-1.194 4.995-6.867 3.965V102.89c0-.834.122-1.734.362-2.66l4.019-15.544c.192-.742.71-1.465 1.288-1.798l13.943-8.068a1.344 1.344 0 011.348-.005c.4.227.63.576.688 1.18"
          fill="#2173ce"
        />
        <path
          d="M339.707 93.49l-7.364 4.252c-.422.243-.816.724-1.044 1.256a714.22 714.22 0 01-1.927 4.525c-.147.34-.384.623-.632.767l-2.708 1.563c-.265.155-.487.028-.478-.294.015-1.202.02-2.4.017-3.59-.003-.96.14-1.994.405-3.044a594.13 594.13 0 003.143-12.971.644.644 0 01.284-.395l9.835-5.678.015-.01c.268-.154.5-.027.509.278.168 4.212.253 8.484.257 12.801a.68.68 0 01-.312.54m.303-13.548c-.01-.311-.233-.468-.453-.467l-.266.079-10.036 5.794a.894.894 0 00-.392.555 541.109 541.109 0 01-3.136 12.955 12.664 12.664 0 00-.405 3.096c-.006 1.27-.011 2.541-.026 3.818 0 .305.212.468.424.473l.258-.075 2.892-1.67c.292-.168.568-.497.732-.876a797.315 797.315 0 001.926-4.526c.212-.49.568-.918.954-1.14l7.236-4.177c.311-.182.558-.608.558-.968a347.862 347.862 0 00-.268-12.87"
          fill="#262424"
        />
        <path
          d="M327.016 100.421l-1.217-.703c.03-.156.064-.314.1-.472l.34.198.016.008.005.003.008.004.003.002.003.002a.017.017 0 01.006.004s.002 0 .003.002h.002l.002.001a.33.33 0 00.16.041.535.535 0 00.269-.083c0 .457.082.846.3.993"
          fill="#3b637e"
        />
        <path
          d="M334.617 85.003l.017-.007-.017.006m.241-.043h.007-.007"
          fill="#39607c"
        />
        <path
          d="M325.819 105.918h-.006c-.155-.002-.26-.125-.26-.343 0 .22.109.343.266.343a.43.43 0 00.212-.065l1.31-.756 1.398-.807c.248-.144.486-.427.633-.767a766.031 766.031 0 001.926-4.525c.02-.044.04-.088.062-.132.232-.48.596-.901.983-1.124l3.465-2.001 3.9-2.251c-1.846 1.065-5.52 3.185-7.365 4.252-.422.243-.816.724-1.045 1.256a788.69 788.69 0 01-1.926 4.525c-.147.34-.384.623-.633.767l-2.708 1.563-.023.013a.417.417 0 01-.184.053h-.007m-.246-3.278"
          fill="#895679"
        />
        <path
          d="M324.643 115.617c-.28 0-.462-.233-.45-.63.153-4.272.223-8.486.22-12.657a11.506 11.506 0 01.378-2.859 586.5 586.5 0 003.337-13.841c.108-.464.428-.925.795-1.136l11.054-6.383a.805.805 0 01.399-.12c.29 0 .5.219.513.598.286 6.775.233 13.684.233 20.698 0 .679-.25 1.147-.888 1.514l-2.142 1.237c-1.523.88-2.99 2.266-4.254 4.042-1.145 1.59-2.346 3.56-2.984 5.42-.158.46-.485.914-.843 1.12-1.79 1.033-3.182 1.837-4.98 2.877a.8.8 0 01-.388.118m15.626-37.307a.575.575 0 00-.283.083l-11.054 6.383c-.255.148-.484.47-.566.814a629.993 629.993 0 01-3.337 13.84 11.155 11.155 0 00-.368 2.76 347.652 347.652 0 01-.221 12.655c-.006.282.128.446.328.446a.537.537 0 00.27-.082l4.99-2.883c.259-.148.483-.469.596-.796.713-2.065 1.886-3.942 3.075-5.6 1.302-1.83 2.823-3.268 4.402-4.179.1-.058 2.032-1.173 2.132-1.232.349-.201.633-.692.64-1.087a342.88 342.88 0 00-.232-20.698c-.014-.268-.165-.426-.372-.426"
          fill="#2146ce"
        />
        <path
          d="M322.766 101.464l-23.142-13.362 4.15-16.212a257.918 257.918 0 0023.141 13.362l-4.15 16.212m-2.567-40.499a1.34 1.34 0 00-1.384-.015l-13.943 8.066c-.58.336-1.096 1.057-1.288 1.8l-4.02 15.544a10.742 10.742 0 00-.36 2.66v17.99c0 .823.438 1.586 1.153 1.998l22.87 13.204V102.89c0-.834.121-1.735.36-2.66l4.021-15.544c.192-.743.71-1.465 1.288-1.798l13.943-8.068a1.34 1.34 0 011.346-.005l-23.986-13.849"
          fill="#2196f3"
        />
        <path
          d="M313.857 74.387c-.537 0-.974-.252-.974-.562 0-.312.437-.563.975-.563s.974.25.974.563c0 .31-.436.562-.974.562"
          fill="#697983"
        />
        <path
          d="M313.857 74.387c-.537 0-.974-.252-.974-.562v.239c0 .312.437.564.975.564s.974-.252.974-.564v-.24c0 .31-.436.563-.974.563"
          fill="#3f444d"
        />
        <path
          d="M313.857 73.262c-.302 0-.547-.142-.547-.316 0-.175.245-.317.547-.317.302 0 .549.142.549.317 0 .174-.247.316-.548.316"
          fill="#fad013"
        />
        <path
          d="M313.857 73.262c-.302 0-.547-.142-.547-.316l-.153.879c0 .223.314.405.701.405.387 0 .701-.182.701-.405l-.153-.88c0 .175-.247.317-.548.317"
          fill="#f89b0f"
        />
        <path
          d="M320.713 78.345c-.539 0-.976-.253-.976-.564 0-.31.437-.562.976-.562.537 0 .974.251.974.562 0 .311-.437.564-.974.564"
          fill="#697983"
        />
        <path
          d="M320.713 78.345c-.539 0-.976-.253-.976-.564v.24c0 .31.437.563.976.563.537 0 .974-.252.974-.562v-.241c0 .311-.437.564-.974.564"
          fill="#3f444d"
        />
        <path
          d="M320.713 77.22c-.303 0-.549-.143-.549-.317 0-.174.246-.316.549-.316.302 0 .546.142.546.316 0 .174-.244.316-.546.316"
          fill="#fad013"
        />
        <path
          d="M320.713 77.22c-.303 0-.549-.143-.549-.317l-.153.878c0 .225.313.407.702.407.386 0 .7-.182.7-.407l-.153-.878c0 .174-.245.316-.546.316"
          fill="#f89b0f"
        />
        <path
          d="M317.285 76.366c-.538 0-.975-.252-.975-.563 0-.31.437-.562.975-.562s.975.252.975.562c0 .31-.437.563-.975.563"
          fill="#697983"
        />
        <path
          d="M317.285 76.366c-.538 0-.975-.252-.975-.563v.24c0 .31.437.562.975.562s.975-.25.975-.562v-.24c0 .31-.437.563-.975.563"
          fill="#3f444d"
        />
        <path
          d="M317.285 75.24c-.302 0-.546-.141-.546-.317 0-.173.244-.315.546-.315.303 0 .547.142.547.315 0 .175-.244.318-.547.318"
          fill="#fad013"
        />
        <path
          d="M317.285 75.24c-.302 0-.546-.141-.546-.317l-.156.88c0 .223.314.405.702.405.388 0 .701-.182.701-.405l-.153-.88c0 .175-.245.318-.548.318"
          fill="#f89b0f"
        />
        <path
          d="M298.564 92.13c.326.187.89.162 1.264-.053l.82-.473-1.174-.676-.82.473c-.203.117-.309.267-.309.411 0 .12.073.233.219.318"
          fill="#373132"
        />
        <path
          d="M299.828 92.077c-.373.217-.94.24-1.264.053-.146-.085-.219-.198-.219-.318v.936c0 .119.074.232.219.316.326.188.89.163 1.264-.052l.82-.473v-.935l-.82.473"
          fill="#1b1817"
        />
        <path
          d="M320.972 105.066c-.146-.084-.217-.197-.219-.315 0-.144.105-.295.31-.413l.82-.473 1.174.677-.821.472c-.375.216-.94.24-1.264.052"
          fill="#373132"
        />
        <path
          d="M322.236 105.014c-.375.216-.94.24-1.264.052-.146-.084-.217-.197-.219-.316v.934c0 .119.073.233.219.317.324.188.89.164 1.264-.053l.82-.473v-.933l-.82.472"
          fill="#1b1817"
        />
        <path
          d="M324.356 106.562a1.08 1.08 0 01-.536-.163l-1.465-.83-.073-.275.277-.073 1.461.83c.193.111.371.137.489.07.117-.07.186-.235.186-.458v-1.492a.202.202 0 01.403 0v1.492c0 .376-.138.663-.387.807a.714.714 0 01-.355.092"
          fill="#262424"
        />
        <path
          d="M319.047 113.1l3.758 2.17v-3.302l-3.758-2.17c-.193-.11-.368-.119-.503-.041-.162.095-.266.313-.266.622v1.256c0 .565.344 1.22.768 1.464"
          fill="#ccded3"
        />
        <path
          d="M318.965 109.514c.134-.077.31-.07.501.042l3.76 2.17-.42.242-3.76-2.17c-.192-.11-.367-.119-.502-.04l.421-.244"
          fill="#ddefe4"
        />
        <path
          d="M323.226 111.725v3.303l-.42.242v-3.302l.42-.243"
          fill="#b0c0bb"
        />
        <path
          d="M321.681 112.83v-.876l-1.115-.644v.876l1.115.644"
          fill="#f7d508"
        />
        <path
          d="M322.256 114.318l-.575-1.488-1.115-.644-1.738.154 3.428 1.978"
          fill="#f3fbf7"
        />
        <path
          d="M320.566 111.31v.876l-1.738.154v-2.034l1.738 1.004M321.681 111.954v.876l.575 1.488v-2.032l-.575-.332"
          fill="#ddefe4"
        />
        <path
          d="M320.672 111.37l1.584.914-.575-.194v-.137l-1.009-.582M320.566 111.311v.135l-1.738-1.14 1.738 1.005"
          fill="#b0c0bb"
        />
        <path
          d="M302.539 103.57l-3.759-2.17v-3.303l3.759 2.17c.424.244.767.902.767 1.467v1.256c0 .309-.103.527-.265.62-.135.078-.31.07-.502-.04"
          fill="#ccded3"
        />
        <path
          d="M303.306 102.99v-1.256c0-.565-.343-1.222-.767-1.467l-3.759-2.17.42-.244 3.759 2.172c.424.244.767.9.767 1.466v1.256c0 .31-.101.527-.264.62l-.42.243c.162-.093.264-.311.264-.62"
          fill="#ddefe4"
        />
        <path
          d="M302.182 101.572v-.875l-1.115-.644v.875l1.116.644"
          fill="#f7d508"
        />
        <path
          d="M302.756 103.06l-.574-1.488-1.115-.644-1.738.155 3.427 1.979"
          fill="#f3fbf7"
        />
        <path
          d="M301.068 100.053v.875l-1.739.155v-2.034l1.738 1.004M302.182 100.697v.875l.574 1.488v-2.033l-.574-.33"
          fill="#ddefe4"
        />
        <path
          d="M301.173 100.113l1.583.914-.574-.194v-.136l-1.01-.584M301.068 100.053v.137l-1.739-1.14 1.738 1.003"
          fill="#b0c0bb"
        />
        <path
          d="M322.805 115.27l-3.758-2.17c-.425-.244-.768-.9-.768-1.467v-1.255c0-.31.103-.526.265-.621l.008-.006c.003 0 .006-.002.008-.003l.012-.006a.393.393 0 01.17-.036.628.628 0 01.303.09l3.76 2.171v3.302m-1.124-3.179v.74l.574 1.488v-2.032l-.574-.333-.358-.206-.757-.437-1.738-1.003v2.033l3.427 1.978-.574-1.488-1.116-.644 1.116.644v-.877.137"
          fill="#e2ece6"
        />
        <path
          d="M321.681 112.83l-1.115-.644v-.74l1.115.644v.74m0-.876l-1.115-.643.758.436-.652-.376 1.01.583"
          fill="#f9e84a"
        />
        <path
          d="M321.681 112.09l-1.115-.644v-.135l1.115.643v.136"
          fill="#eadb4c"
        />
        <path
          d="M322.256 114.318l-3.428-1.978 1.738-.154 1.115.644.575 1.488"
          fill="#f9fdfc"
        />
        <path
          d="M318.828 112.34v-2.034l1.738 1.004-1.738-1.003 1.738 1.14v.739l-1.738.154M322.256 114.318l-.575-1.488v-.74l.575.194-.575-.33.575.332v2.032"
          fill="#ecf6f1"
        />
        <path
          d="M322.256 112.284l-.575-.194v-.137l-1.009-.582.651.376.358.206.575.331M320.566 111.446l-1.738-1.14 1.738 1.005v.135"
          fill="#d2dcd9"
        />
        <path
          d="M302.844 103.661a.605.605 0 01-.305-.091l-3.759-2.17v-3.302l3.759 2.169c.424.244.767.902.767 1.467v1.256c0 .309-.103.526-.265.62a.395.395 0 01-.197.051m-.662-2.828v.74l.574 1.487v-2.033l-.574-.33-1.01-.584 1.011.584-1.116-.644-1.738-1.004v2.035l3.427 1.976-.574-1.488v-.875.136m-3.402-2.736"
          fill="#e2ece6"
        />
        <path
          d="M303.041 103.61c.163-.094.265-.311.265-.62v-1.256 1.256c0 .309-.103.526-.265.62m-.502-3.343l-3.759-2.17 3.759 2.17"
          fill="#ecf6f1"
        />
        <path
          d="M302.182 101.572l-1.115-.644v-.738l1.116.643v.738"
          fill="#f9e84a"
        />
        <path
          d="M302.182 100.833l-1.115-.643v-.137l1.116.643v.137"
          fill="#eadb4c"
        />
        <path
          d="M302.756 103.06l-3.427-1.976 1.738-.156 1.116.643.573 1.49"
          fill="#f9fdfc"
        />
        <path
          d="M299.329 101.084v-2.035l1.738 1.004-1.738-1.004 1.738 1.14v.74l-1.738.154M302.756 103.06l-.574-1.488v-.74l.574.195-.574-.331.574.331v2.035"
          fill="#ecf6f1"
        />
        <path
          d="M302.756 101.027l-.574-.194v-.136l-1.01-.584 1.011.584.573.33M301.068 100.189l-1.739-1.14 1.738 1.004v.137"
          fill="#d2dcd9"
        />
        <path
          d="M327.894 79.25l10.337-5.967a.652.652 0 01.593 0l.833.481.124.171-.124.17-10.338 5.968a.651.651 0 01-.59 0l-.835-.482-.122-.17.122-.17"
          fill="#3eb7f3"
        />
        <path
          d="M329.024 80.371a.616.616 0 01-.295-.07l-.835-.484-.122-.17v-.227l.122.171.835.482a.595.595 0 00.295.071.593.593 0 00.295-.07l10.338-5.97.124-.169v.226l-.124.17-10.338 5.97a.605.605 0 01-.295.069"
          fill="#2173ce"
        />
        <path
          d="M329.024 80.146a.602.602 0 01-.295-.073l-.835-.482.835.482a.595.595 0 00.295.07.581.581 0 00.295-.07l10.338-5.968-10.338 5.968a.596.596 0 01-.295.071"
          fill="#2173ce"
        />
        <path
          d="M309.009 68.346l10.338-5.967a.651.651 0 01.59 0l.835.482.123.17-.123.17-10.335 5.968a.652.652 0 01-.593 0l-.835-.48-.122-.17.122-.171"
          fill="#3eb7f3"
        />
        <path
          d="M310.139 69.467a.619.619 0 01-.295-.07l-.835-.482-.122-.172v-.225l.122.17.835.481a.599.599 0 00.295.073.602.602 0 00.296-.073l10.337-5.967.123-.171v.227l-.123.17-10.335 5.97a.613.613 0 01-.298.07"
          fill="#2173ce"
        />
        <path
          d="M310.139 69.24a.605.605 0 01-.295-.07l-.835-.483.835.482a.599.599 0 00.295.073"
          fill="#e11d25"
        />
        <path
          d="M312.787 70.526l10.337-5.966a.646.646 0 01.59 0l.835.481.124.171-.124.17-10.335 5.969a.652.652 0 01-.593 0l-.835-.482-.122-.17.122-.173"
          fill="#3eb7f3"
        />
        <path
          d="M313.916 71.647a.586.586 0 01-.295-.07l-.835-.482-.122-.171v-.226l.122.171.835.482c.081.046.19.07.295.07a.614.614 0 00.297-.07l10.336-5.968.124-.171v.226l-.124.17-10.335 5.968a.602.602 0 01-.298.07"
          fill="#2173ce"
        />
        <path d="M324.55 65.383l.123-.171-.124.17" fill="#e11d25" />
        <path
          d="M316.563 72.708L326.9 66.74a.652.652 0 01.593 0l.835.482.122.171-.122.17-10.338 5.969a.658.658 0 01-.59 0l-.836-.483-.123-.17.123-.17"
          fill="#3eb7f3"
        />
        <path
          d="M317.693 73.828a.62.62 0 01-.295-.07l-.835-.482-.123-.171v-.226l.123.17.835.483c.082.046.19.07.295.07a.616.616 0 00.296-.07l10.338-5.97.122-.17v.227l-.122.17-10.338 5.968a.605.605 0 01-.296.071"
          fill="#2173ce"
        />
        <path
          d="M320.34 74.89l10.336-5.97a.665.665 0 01.593 0l.835.482.122.17-.122.172-10.338 5.967a.651.651 0 01-.59 0l-.835-.48-.124-.172.124-.17"
          fill="#3eb7f3"
        />
        <path
          d="M321.47 76.009a.605.605 0 01-.295-.07l-.834-.483-.124-.171v-.226l.124.171.834.481a.599.599 0 00.296.07c.107 0 .214-.02.295-.07l10.338-5.967.122-.171v.227l-.122.17-10.338 5.968a.595.595 0 01-.295.071"
          fill="#2173ce"
        />
        <path
          d="M324.118 77.07l10.336-5.97a.652.652 0 01.592 0l.835.483.122.172-.122.17-10.338 5.967a.651.651 0 01-.59 0l-.835-.48-.123-.172.123-.17"
          fill="#3eb7f3"
        />
        <path
          d="M325.248 78.189a.616.616 0 01-.295-.07l-.836-.482-.122-.17v-.227l.06.085.062.086.836.481a.605.605 0 00.295.071.616.616 0 00.295-.07l10.338-5.968.122-.17v-.001.225l-.122.171-10.338 5.97a.605.605 0 01-.295.069m-1.253-.949"
          fill="#2173ce"
        />
        <path
          d="M325.248 77.963a.616.616 0 00.295-.07l10.338-5.97.122-.17-.122.172-10.338 5.969a.605.605 0 01-.295.07m-1.193-.639l-.06-.085.06.085"
          fill="#e11d25"
        />
        <path
          d="M348.263 109.705v-.426c.15-4.392-3.109-6.701-6.952-4.748a9.231 9.231 0 01.03.751v.418c0 .174.094.337.246.425l6.91 3.988a.47.47 0 01-.234-.408"
          fill="#100f0d"
        />
        <path
          d="M333.435 109.934c1.073-1.858 2.481-3.29 3.891-4.104.884-.51 1.766-.777 2.567-.756l.85.023h.005a2.99 2.99 0 011.408.38l2.703 1.57c-1.14-.648-2.63-.44-4.117.419-1.488.859-2.976 2.37-4.106 4.329-2.264 3.921-2.252 8.15.027 9.448l-2.702-1.57c-.966-.556-1.43-1.657-1.512-1.79-1.07-1.768-.742-4.954.988-7.95"
          fill="#1b1918"
        />
        <path
          d="M336.636 111.796c1.13-1.959 2.617-3.47 4.105-4.33 1.488-.858 2.978-1.065 4.117-.418.766.44 1.392 1.59 1.634 2.659.15.667-7.347 11.736-7.347 11.736-.857.18-2.058.048-2.483-.197-2.32-1.349-2.29-5.528-.026-9.45"
          fill="#262424"
        />
        <path
          d="M337.268 121.13c2.151 1.245 5.64-.77 7.792-4.497 2.15-3.728 2.15-7.755 0-8.997-2.15-1.244-5.64.771-7.792 4.499-2.15 3.726-2.15 7.754 0 8.996"
          fill="#1b1918"
        />
        <path
          d="M338.725 118.608c1.349.778 3.532-.484 4.878-2.816 1.348-2.333 1.348-4.855 0-5.633-1.346-.778-3.53.483-4.878 2.816-1.346 2.333-1.346 4.855 0 5.633"
          fill="#100f0d"
        />
        <path
          d="M337.72 116.186l1.613-.325c.029.148.074.286.136.405l-.998 2.162c-.539-.465-.79-1.276-.752-2.242zm2.227 2.628l.997-2.163c.147-.064.294-.15.442-.253l.996 1.009c-.783.794-1.653 1.295-2.435 1.407zm3.91-3.497l-.997-1.008a3.69 3.69 0 00.136-.563l1.614-1.537c.037.92-.213 2.02-.753 3.108zm.19-4.787l-1.615 1.538a.977.977 0 00-.355-.093v-1.959c.558-.146 1.088-.11 1.526.143.17.098.318.222.444.372zm-5.322 2.445a7.79 7.79 0 011.528-1.907v1.96a4.453 4.453 0 00-.356.505l-1.615.326a8.25 8.25 0 01.443-.884zm-.19 5.964c1.452.837 3.807-.522 5.259-3.036 1.453-2.518 1.453-5.236 0-6.075-1.453-.837-3.808.52-5.26 3.037-1.453 2.517-1.453 5.235 0 6.074"
          fill="#d0cfb2"
        />
        <path
          d="M339.771 112.75v-1.206c.157-.171.318-.33.482-.477v1.96a4.455 4.455 0 00-.356.506l-1.615.326c.058-.138.123-.276.192-.413l.941-.19a4.534 4.534 0 01.356-.506M338.962 115.936l.372-.075c.028.148.073.286.135.405l-.998 2.162a1.874 1.874 0 01-.39-.476l.907-1.962-.026-.054M344.61 112.209c.037.92-.213 2.02-.753 3.108l-.412-.417a7.15 7.15 0 00.653-2.205l.512-.486M341.902 117.129l-.64-.648.123-.083.997 1.009c-.783.794-1.653 1.295-2.435 1.407l.217-.471c.582-.233 1.181-.651 1.738-1.214M343.352 110.04l.251.12c.17.097.318.221.444.371l-1.615 1.538a.979.979 0 00-.355-.095v-.302l1.489-1.419-.215-.212"
          fill="#a9a891"
        />
        <path
          d="M340.836 114.953c.18.104.476-.065.657-.38.18-.314.18-.653 0-.759-.181-.104-.477.066-.657.38-.181.315-.181.653 0 .759M340.975 113.33c.104.061.274-.036.379-.218.104-.181.104-.378 0-.437-.105-.061-.274.036-.38.218-.104.18-.104.377 0 .438M341.91 114.764c-.147.058-.284.254-.306.435-.024.183.075.283.222.226.145-.058.283-.253.306-.435.024-.183-.076-.281-.223-.226M340.726 115.706c-.023-.157-.16-.192-.306-.081-.147.111-.246.327-.223.482.023.156.16.193.307.082.146-.112.244-.328.222-.483M340.148 114.819c.132-.154.186-.37.117-.484-.067-.114-.228-.081-.36.072-.131.155-.184.37-.116.484.066.114.228.081.36-.072M342.064 113.298c-.068.19-.015.346.116.35.133 0 .293-.153.36-.346.068-.19.016-.347-.116-.349-.132 0-.293.153-.36.345"
          fill="#201f1e"
        />
        <path
          d="M331.641 119.058v-.777c0-2.3.669-4.806 1.79-7.158 1.618-3.383 4.177-6.442 7.042-8.095l.592-.343c2.866-1.654 5.424-1.549 7.04-.033 1.086 1.019 1.747 2.675 1.79 4.864-.049-3.184-1.096-5.009-2.322-6.158-1.807-1.694-4.55-1.68-7.524.035l-.593.343c-2.974 1.716-5.717 4.872-7.525 8.653-1.254 2.624-1.916 5.305-1.916 7.656 0 2.352 1.647 2.065 2.55 1.544-.572.33-.924.128-.924-.532"
          fill="#414042"
        />
        <path
          d="M348.105 102.654c-1.616-1.518-4.174-1.623-7.04.03l-.593.344c-2.864 1.653-5.423 4.713-7.04 8.095-1.122 2.352-1.79 4.86-1.79 7.158v.777c0 .658.351.861.922.531.16-.09.339-.311.477-.553.136-.239.234-.498.234-.678v-.426c0-4.686 3.404-10.685 7.495-13.122 4.089-2.287 7.494-.217 7.494 4.47v.425a.465.465 0 00.233.407.467.467 0 00.476.003c.572-.329.923-.938.923-1.597v-.777c0-2.3-.668-4.034-1.79-5.087"
          fill="#262424"
        />
        <path
          d="M298.848 103.234c-.067.114-.062.228.013.32l.072.08.268.288v-.96s-.285.159-.352.272"
          fill="#100f0d"
        />
        <path
          d="M322.452 121.156V119.7c0-.682.346-1.518.937-1.858l9.51-5.492s-1.02.195-1.773-.052l-6.893 3.978a4.121 4.121 0 01-1.691.428 3.83 3.83 0 01-1.05-.1c-3.395-.733-7.028-2.712-11.665-5.388-4.581-2.645-8.65-5.098-10.894-7.582l-.073-.082c-.074-.09-.079-.204-.011-.318.067-.113.352-.271.352-.271v-1.09l-1.153.67c-.845.488-1.537 1.686-1.537 2.661v1.636c0 .942.261 1.663.701 2.122l-.002.003c2.65 2.987 6.967 5.746 12.471 8.924 5.506 3.178 10.126 5.015 14.158 5.884l.005-.005a1.79 1.79 0 01-.409-.166c-.69-.391-.982-1.239-.982-2.445"
          fill="#333232"
        />
        <path
          d="M333.914 111.764l-10.525 6.077c-.59.34-1.074 1.177-1.074 1.86v1.455c0 1.205.43 2.052 1.12 2.445.604.344 1.406.34 2.297-.075.174-.082 6.832-3.937 6.832-3.937l1.35-7.824"
          fill="#262424"
        />
        <path
          d="M320.174 118.857a.516.516 0 01-.176-.034c-3.576-1.322-7.197-3.04-10.814-5.128-3.619-2.09-7.234-4.548-10.807-7.354-.324-.255-.587-.77-.586-1.146 0-.235.103-.36.26-.36a.472.472 0 01.226.07l.105.07c3.571 2.808 7.188 5.267 10.805 7.357 3.619 2.087 7.237 3.805 10.813 5.127l.105.048a1.032 1.032 0 01.482.849c0 .304-.174.5-.413.5"
          fill="#424143"
        />
        <path
          d="M312.775 118.907l.203-.118v-2.209l-7.373-4.255-.204.116 7.374 4.257v2.209"
          fill="#262424"
        />
        <path
          d="M312.548 116.828l-6.921-3.994v1.686l6.92 3.995v-1.686"
          fill="#fff"
        />
        <path
          d="M305.401 114.652l7.374 4.255v-2.21l-7.374-4.256v2.21"
          fill="#414042"
        />
        <path
          d="M317.55 111.214a76.533 76.533 0 01-6.34-3.285 88.914 88.914 0 01-6.327-4.028l.176 1.277c.125.934.707 1.953 1.383 2.401a89.798 89.798 0 004.762 2.958 80.007 80.007 0 004.763 2.543c.675.329 1.261-.015 1.399-.798l.183-1.07M318.054 108.262a76.81 76.81 0 01-6.834-3.511 89.996 89.996 0 01-6.83-4.377l.23 1.634a88.411 88.411 0 006.597 4.216 75.81 75.81 0 006.6 3.403c-.003.028.153-.881.236-1.365"
          fill="#262221"
        />
        <path
          d="M317.817 109.627a75.806 75.806 0 01-6.6-3.403 88.41 88.41 0 01-6.597-4.216h-.002l.265 1.894a88.93 88.93 0 006.328 4.027 76.532 76.532 0 006.339 3.285l.267-1.587"
          fill="#2196f3"
        />
        <path
          d="M339.206 97.636l-2.759 1.594c-.286.164-.517-.012-.517-.391v-.226c0-.38.233-.821.517-.988l2.76-1.59c.284-.165.515.01.515.39v.224c0 .381-.23.823-.516.988"
          fill="#262424"
        />
        <path
          d="M335.931 98.839v-.226c0-.38.23-.821.516-.988l2.76-1.59c.169-.099.318-.077.413.037l-.015-.017c-.122-.125-.264-.21-.392-.21l-.213.06-2.762 1.596c-.293.17-.523.608-.522.988l-.008.226c0 .172.049.302.126.379a.573.573 0 00.407.198l.003-.002c-.184.001-.313-.167-.313-.45"
          fill="#414042"
        />
        <path
          d="M338.514 97.235l-1.375.794a.74.74 0 00-.335.579v.415l2.045-1.18v-.415c0-.213-.15-.3-.335-.193"
          fill="#191716"
        />
        <path
          d="M331.238 118.668l-5.276 3.046c-.26.151-.455-.017-.4-.344.27-1.583 1.528-3.388 3.037-4.26 1.511-.872 2.767-.517 3.036.754.057.26-.137.654-.397.804"
          fill="#100f0d"
        />
        <path
          d="M330.686 118.024c-.753-.426-1.692-.418-2.407.07-1.258.86-2.5 2.015-2.717 3.276-.055.326.14.494.4.346l5.276-3.047.222-.206-.774-.438"
          fill="#414042"
        />
        <path
          d="M300.824 85.394c.985-3.849 2.143-8.31 3.126-12.15.067-.258.28-.372.472-.259l.025.015a266.163 266.163 0 0010.669 6.5 256.107 256.107 0 0010.82 5.905l.027.016c.2.116.3.41.233.669-.983 3.838-2.14 8.3-3.126 12.148-.262 1.024-.758 1.461-1.448 1.338-2.9-.606-7.298-2.204-11.242-4.482l-.205-.118c-4.008-2.337-6.406-4.63-8.873-7.132-.56-.6-.74-1.425-.477-2.45zm26.107-.134a253.28 253.28 0 01-11.667-6.336 264.014 264.014 0 01-11.49-7.034c-1.321 5.158-2.822 11.03-4.15 16.212l-.01.05c-.237 1.087.694 2.392 1.612 3.143 2.734 2.234 6.108 4.708 9.97 6.94 3.865 2.23 6.623 3.334 9.374 4.366a1.644 1.644 0 002.2-1.135s2.812-10.938 4.163-16.206"
          fill="#2d2c30"
        />
        <path
          d="M329.402 85.557a.64.64 0 00-.283.397 595.553 595.553 17.817 01-3.144 12.97c-.264 1.05-.407 2.084-.404 3.044a234.59 234.59 21.288 01-.019 3.592c-.008.323.214.448.48.293l2.707-1.563c.247-.144.486-.429.632-.768a766.031 766.031 18.128 001.927-4.523c.228-.532.622-1.013 1.044-1.256l7.364-4.252a.685.685 0 00.311-.54 327.547 327.547 0 00-.255-12.803h-.002c-.01-.31-.25-.426-.524-.267z"
          fill="#6cceda"
        />
        <path
          d="M325.122 105.076l1.213.701c.328.189.594-.011.594-.448v-4.204c0-.437-.266-.945-.593-1.135l-2.084-1.202c-.328-.19-.593.011-.593.447v3.044c0 1.078.655 2.331 1.465 2.797"
          fill="#c8c7c0"
        />
        <path
          d="M326.93 105.329v-4.204c0-.437-.267-.945-.594-1.135l-2.084-1.202c-.317-.184-.576-.002-.593.404.028-.659.659-1.175 1.131-1.182l.244.072 2.086 1.203c.328.189.593.698.593 1.134v4.457a.795.795 0 01-.042.27 1.03 1.03 0 01-1.157.698c.24.038.415-.16.415-.515"
          fill="#8c8984"
        />
        <path
          d="M325.936 85.405a256.107 256.107 0 01-10.82-5.904A266.163 266.163 0 01304.446 73l-.024-.014c-.193-.113-.406.002-.472.26-.983 3.839-2.141 8.3-3.126 12.148-.264 1.026-.082 1.85.478 2.45 2.467 2.501 4.865 4.796 8.872 7.132l.205.119c3.945 2.277 8.342 3.875 11.243 4.481.69.123 1.185-.314 1.448-1.338.986-3.847 2.142-8.31 3.124-12.147.067-.26-.031-.553-.231-.668z"
          fill="#6cceda"
        />
        <path
          d="M339.05 80.654a.498.498 0 00-.195.068l-3.94 2.274 4.575 3.443a327.547 327.547 0 00-.11-5.45l-.002.003c-.007-.234-.144-.358-.328-.338zm-5.023 2.855l-.551.319 6.053 4.55a327.547 327.547 0 00-.016-.738zM304.663 74.115c-.15-.088-.317.002-.369.203-.495 1.933-1.04 4.049-1.574 6.119l7.147-3.152a208.06 208.06 0 01-5.185-3.16zm6.473 3.944l-8.803 3.881-.26 1.012 9.918-4.374a208.06 208.06 0 01-.855-.52z"
          fill="#bae8ec"
        />
      </g>
      <g id="dot-2">
        <g
          transform="matrix(1.10621 0 0 -1.10621 55.858 275.914)"
          fillOpacity=".337"
          filter="url(#h)"
        >
          <path d="M172.904 97.043a5.64 3.191-6.949 00.092-4.263 5.64 3.191-6.949 00-7.403.064 5.64 3.191-6.949 01.47-.304 5.64 3.191-6.949 017.918-.33 5.64 3.191-6.949 01-.568 4.573 5.64 3.191-6.949 01-.509.26z" />
          <ellipse
            cx="192.654"
            cy="2.199"
            transform="matrix(.86603 .5 .86603 -.5 0 0)"
            rx="4.243"
            ry="4.899"
          />
        </g>
        <g
          transform="scale(1.633 .9428) rotate(-45 609.73 67.2)"
          strokeWidth=".669"
        >
          <ellipse
            cx="221.774"
            cy="-193.833"
            rx="3.52"
            ry="4.064"
            fill="#d5d5d5"
          />
          <ellipse
            cx="222.697"
            cy="-194.397"
            rx="3.52"
            ry="4.064"
            fill="#f2f2f2"
          />
        </g>
      </g>
      <g id="dot-1">
        <g
          transform="matrix(1.10621 0 0 -1.10621 90.5 255.914)"
          fillOpacity=".337"
          filter="url(#i)"
        >
          <path d="M172.904 97.043a5.64 3.191-6.949 00.092-4.263 5.64 3.191-6.949 00-7.403.064 5.64 3.191-6.949 01.47-.304 5.64 3.191-6.949 017.918-.33 5.64 3.191-6.949 01-.568 4.573 5.64 3.191-6.949 01-.509.26z" />
          <ellipse
            cx="192.654"
            cy="2.199"
            transform="matrix(.86603 .5 .86603 -.5 0 0)"
            rx="4.243"
            ry="4.899"
          />
        </g>
        <g
          transform="scale(1.633 .9428) rotate(-45 594.73 30.986)"
          strokeWidth=".669"
        >
          <ellipse
            cx="221.774"
            cy="-193.833"
            rx="3.52"
            ry="4.064"
            fill="#d5d5d5"
          />
          <ellipse
            cx="222.697"
            cy="-194.397"
            rx="3.52"
            ry="4.064"
            fill="#f2f2f2"
          />
        </g>
      </g>
      <g id="dot-3">
        <g
          transform="matrix(1.10621 0 0 -1.10621 21.218 295.914)"
          filter="url(#j)"
          fillOpacity=".337"
        >
          <path d="M172.904 97.043a5.64 3.191-6.949 00.092-4.263 5.64 3.191-6.949 00-7.403.064 5.64 3.191-6.949 01.47-.304 5.64 3.191-6.949 017.918-.33 5.64 3.191-6.949 01-.568 4.573 5.64 3.191-6.949 01-.509.26z" />
          <ellipse
            cx="192.654"
            cy="2.199"
            transform="matrix(.86603 .5 .86603 -.5 0 0)"
            rx="4.243"
            ry="4.899"
          />
        </g>
        <g
          transform="scale(1.633 .9428) rotate(-45 624.731 103.412)"
          strokeWidth=".669"
        >
          <ellipse
            cx="221.774"
            cy="-193.833"
            rx="3.52"
            ry="4.064"
            fill="#d5d5d5"
          />
          <ellipse
            cx="222.697"
            cy="-194.397"
            rx="3.52"
            ry="4.064"
            fill="#f2f2f2"
          />
        </g>
      </g>
    </Svg>
  )
}

export default HeroSvg
