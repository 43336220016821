import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

import Cta from "./Cta"

const Container = styled.section`
  max-width: 130rem;
  margin: 6rem auto;
  padding: 0 1.5rem;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0rem;
`

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 21, 64, 0.25);
  transition: all 0.2s;
  z-index: 3;
  width: 100%;
  max-width: 35rem;
  position: relative;
  margin: 1.5rem;
  padding-bottom: 3rem;
  overflow: hidden;
  @media only screen and (max-width: 32.5em) {
    max-width: 50rem;
  }
`

const BlogDate = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  z-index: 4;
  color: #505050;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
`

const Image = styled(Img)`
  width: 100%;
  height: 17.5rem;
`

const CardLink = styled(Link)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  color: #505050;
  text-align: center;
  padding: 0 1.5rem;
  flex: 1;
`

const Body = styled.p`
  padding: 1.5rem;
  font-weight: 400;
  color: #505050;
  min-height: 17rem;
`

const RecentBlogPosts = ({ edges = [] }) => {
  return (
    <Container>
      <Primary>Recent Blog Posts</Primary>
      <Subtitle>Check out our most recent blog posts</Subtitle>
      <Content>
        {edges.map(({ node }, count) => (
          <Card key={count}>
            <CardLink to={node.fields.slug} style={{ textDecoration: "none" }}>
              <BlogDate>{node.frontmatter.date}</BlogDate>
              {node.frontmatter.headingImage && (
                <Image
                  fluid={node.frontmatter.headingImage.childImageSharp.fluid}
                />
              )}
              <Secondary>{node.frontmatter.title}</Secondary>
              <Body>{node.excerpt}</Body>
              <Cta to={node.fields.slug} variant>
                Continue reading
              </Cta>
            </CardLink>
          </Card>
        ))}
      </Content>
    </Container>
  )
}

export default RecentBlogPosts
