import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import Img from "gatsby-image"

const Container = styled.section``

const Content = styled.div`
  max-width: 130rem;
  margin: 3rem auto 7rem;
  padding: 0 1.5rem;
  overflow: hidden;
`

const Tagline = styled.h5`
  font-size: 2.5rem;
  font-weight: 700;
  color: #111;
  text-align: center;
  line-height: 1.3;
  grid-column: 1 / -1;
  max-width: 100rem;
  margin: 0 auto;
  padding: 1.5rem 0;
`

const Brand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  overflow: hidden;
  flex-wrap: wrap;
  @media only screen and (max-width: 40em) {
    :nth-of-type(1) {
      display: none;
    }
  }
`

const Image = styled(Img)`
  filter: grayscale(100%);
  margin: 0 2rem;
  flex: 0 0 auto;
`

const Charities = ({ images }) => {
  const [totalCharities, setTotalCharities] = useState(1500)
  const [totalDonated, setTotalDonated] = useState(1332264.5)

  const getData = async () => {
    try {
      const res = await fetch(
        "https://app.skipthedepot.com/api/totalCharityCount",
        // "http://localhost:3001/totalCharityCount",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )

      if (res.ok) {
        const json = await res.json()
        setTotalCharities(Math.ceil(json.total / 100) * 100)
      }

      const resTwo = await fetch(
        "https://app.skipthedepot.com/api/totalDonated",
        // "http://localhost:3001/totalDonated",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )

      if (resTwo.ok) {
        const jsonTwo = await resTwo.json()
        setTotalDonated(jsonTwo.total / 100)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Container>
      <Content>
        <Tagline>
          Over {totalCharities} organizations and more than{" "}
          {totalDonated.toLocaleString("en-CA", {
            style: "currency",
            currency: "CAD",
          })}{" "}
          donated through SkipTheDepot
        </Tagline>
        <Brand>
          {images &&
            images.map((image, count) => (
              <Image
                key={count}
                fixed={image.node.childImageSharp.fixed}
                alt={`Charity image ${count}`}
                objectFit="contain"
              />
            ))}
        </Brand>
      </Content>
    </Container>
  )
}

export default Charities
